.invalid-feedback {
  color: #D0312D;
  margin-top: 5px;
}

.control-invalid {
  border: 1px solid #D0312D;
}

.control-valid {
  border: 1px solid green;
}
